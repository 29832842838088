import React, { useEffect } from "react"

import Layout from "../layout/layout"
import SEO from "../components/seo"

import Supports from "../components/Supports"
import { AnalyticsEventCategories, emitAnalyticsEvent } from "../analytics"

const IndexPage = () => {
    useEffect(() => {
        emitAnalyticsEvent("Page view", AnalyticsEventCategories.SUPPORT)
    }, [])

    return (
        <Layout>
            <SEO title="Support" />
            <Supports />
        </Layout>
    )
}

export default IndexPage
