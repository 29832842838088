import React, { useEffect, useState } from "react"
import "./styles.scss"
import { useStaticQuery, graphql } from "gatsby"
import { AnalyticsEventCategories, emitAnalyticsEvent } from "../../analytics"

const supportsBlock = [
    {
        desktop_image_name: "calendar-preview",
        mobile_image_name: "calendar-preview-mobile",

        theme: "calendar",
        preview: null,
        title: "Calendar.AI",
        description: "If you have any questions or feedback, please contact our support team!",
        buttons: [
            {
                type: "android",
                label: "ANDROID SUPPORT",
                href: "https://support-calendar-android.sync.ai/hc/en-us",
                actionType: "Click Calendar Android",
                actionCategory: "Support",
            },
            {
                type: "ios",
                label: "iOS SUPPORT",
                href: "https://support-calendar-ios.sync.ai/hc/en-us",
                actionType: "Click Calendar iOS",
                actionCategory: "Support",
            },
        ],
    },
    {
        desktop_image_name: "calls-preview",
        mobile_image_name: "calls-preview-mobile",

        theme: "calls",
        preview: null,
        title: "Calls.AI",
        description: "If you have any questions or feedback, please contact our support team!",
        buttons: [
            {
                type: "android",
                label: "ANDROID SUPPORT",
                href: "https://support-calls.sync.ai/hc/en-us",
                actionType: "Click Calls Android",
                actionCategory: "Support",
            },
        ],
    },
]

function getMobileOperatingSystem() {
    if (!window) {
        return "desktop"
    }
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    if (/android/i.test(userAgent)) {
        return "android"
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios"
    }

    return "desktop"
}

function SupportBox({ preview_desktop, preview_mobile, theme, title, description, buttons }) {
    const [deviceButtons, setDeviceButtons] = useState([])
    console.log({deviceButtons})

    useEffect(() => {
        const deviceType = getMobileOperatingSystem()
        const supportedButtons = buttons.reduce((memo, button) => {
            if (deviceType === "desktop") {
                return memo.concat(button)
            }

            if (button.type === deviceType) {
                memo.push(button)
            }

            return memo
        }, [])

        setDeviceButtons(supportedButtons)
    }, [])

    const sectionIsVisiableForMobileDevices = deviceButtons.length > 0 ? "" : "hidden-section"

    const handleBtnClick = ({ actionType, actionCategory, href }) => {
        if (actionType && actionCategory) {
            emitAnalyticsEvent(actionType, actionCategory)
        }
        window.open(href, "_blank")
    }

    return (
        <div className={`support-box-container ${theme} ${sectionIsVisiableForMobileDevices}`}>
            <div className={"preview-image-wrapper"}>
                <img src={preview_desktop} alt={title} className={"preview-desktop"} />
                <img src={preview_mobile} alt={title} className={"preview-mobile"} />
            </div>
            <div className="support-box-container__body">
                <p className={"support-box-container__title"}>{title}</p>
                <p className={"support-box-container__description"}>{description}</p>
                <div className={"support-box-container__btn-wrapper"}>
                    {deviceButtons.map((button, i) => {
                        return (
                            <button className={"support-button"} onClick={handleBtnClick.bind(null, button)}>
                                {button.label}
                            </button>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default function Supports() {
    const {
        calendarPreviewMobileImage,
        calendarPreviewDesktopImage,
        callsPreviewDesktopImage,
        callsPreviewMobileImage,
    } = useStaticQuery(graphql`
        query supportsImages {
            calendarPreviewMobileImage: file(
                sourceInstanceName: { eq: "images" }
                name: { eq: "calendar-preview-mobile" }
            ) {
                publicURL
            }

            calendarPreviewDesktopImage: file(sourceInstanceName: { eq: "images" }, name: { eq: "calendar-preview" }) {
                publicURL
            }

            callsPreviewDesktopImage: file(sourceInstanceName: { eq: "images" }, name: { eq: "calls-preview" }) {
                publicURL
            }

            callsPreviewMobileImage: file(sourceInstanceName: { eq: "images" }, name: { eq: "calls-preview-mobile" }) {
                publicURL
            }
        }
    `)

    supportsBlock[0].preview_desktop = calendarPreviewDesktopImage.publicURL
    supportsBlock[0].preview_mobile = calendarPreviewMobileImage.publicURL

    supportsBlock[1].preview_desktop = callsPreviewDesktopImage.publicURL
    supportsBlock[1].preview_mobile = callsPreviewMobileImage.publicURL

    return (
        <div className={"support-container"}>
            <div className="support-container__header">
                <p className="product-title text-center">Support</p>
            </div>
            <div className="support-container__body">
                {supportsBlock.map((support, i) => (
                    <SupportBox {...support} key={i} />
                ))}
            </div>
        </div>
    )
}
